import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';
import cn from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Loader from './Loader';

import './Modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Modal = props => {
  /*
    Modals must live on the outermost level unless you add the isNestedModal prop.
  */
  const { visible, close, isNestedModal, smallForm } = props;

  // if someone can clean this up, we have a lot of cases where we are passing dynamic functions that are redefined on every call
  // ie: closeModal={() => do something } forces a rerender every time which blocks the ability
  // to use the Mousetrap binding below
  const closeModal = useCallback(() => close(), [close]);

  useEffect(() => {
    if (!visible) return;
    Mousetrap.bind('esc', () => closeModal());
    return () => {
      Mousetrap.unbind('esc');
    };
  }, [visible, closeModal]);

  const stopProp = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const modal = (
    <div onClick={props.close} className={cn('modal-outer-container', props.className || '', { visible: props.visible })}>
      {props.visible && (
        <div onClick={stopProp} className={cn('modal-container', props.innerClassName || '')}>
          <div className={cn('modal-inner-container', { smallForm })}>
            <div ref={props.scrollRef} className={cn('modal-content', { noPadding: !!props.noPadding }, props.contentClassName || '')}>
              {props.title && (
                <div className='modal-header-section'>
                  <h1 className='modal-header'>{props.title}</h1>
                  {props.subtitle && <h2 className='modal-subheader'>{props.subtitle}</h2>}
                </div>
              )}
              {props.children}
            </div>
            {props.showClose && (
              <div onClick={props.close} className='close-icn'>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
            <div className={cn('loader-container', { saving: props.saving })}>
              <Loader size={70} />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  // There is a bug where Portal links are not linking to the product due to a bug in the React Portal
  // implentation. This should fix the issue. If you figure it out please remove this effect.
  //
  // You can also solve this by using portalBugOpenUrl in helpers.js, see that function for details.
  useEffect(() => {
    if (isNestedModal) {
      setTimeout(() => {
        Array.from(document.querySelectorAll('.modal-outer-container a')).forEach(a => {
          a.onclick = () => window.open(a.href, a.target);
        });
      }, 200);
    }
  }, [isNestedModal]);

  return isNestedModal ? ReactDOM.createPortal(modal, document.querySelector('body')) : modal;
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.any,

  // Optional
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  saving: PropTypes.bool,
  showClose: PropTypes.bool,
  scrollRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isNestedModal: PropTypes.bool,
  noPadding: PropTypes.bool,
  smallForm: PropTypes.bool
};

export default Modal;
