import React from 'react';
import PropTypes from 'prop-types';
import './Landing.scss';

import StorylinqAnimatedText from './StorylinqAnimatedText';
import PublicFooter from '../../Components/General/PublicFooter';

const Landing = props => {
  const { openSignupModal, setIsSignup } = props;

  const openSignup = () => {
    setIsSignup(true);
    openSignupModal();
  };

  const openLogin = () => {
    setIsSignup(false);
    openSignupModal();
  };

  return (
    <div className='landing-outer'>
      <div className='landing-inner'>
        <div className='landing-buttons'>
          <div className='get-started-button' onClick={openSignup}>
            Get Started
          </div>
          <div className='login-button' onClick={openLogin}>
            Login
          </div>
        </div>
        <div className='landing-first-impression'>
          <img
            src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/useBackground.jpeg'
            alt='pat-on-stage'
            className='pat-standing-on-stage-image'
          />
          <div className='first-impression-text-container'>
            <StorylinqAnimatedText />
            <div className='first-impression-subtext-container'>
              <span>Learn</span>
              <span>Build</span>
              <span>Present</span>
            </div>
          </div>
        </div>

        <div className='landing-section landing-flex'>
          <div className='landing-sub-section'>
            <div className='landing-sub-section-header'>What do we do?</div>
            <div className='landing-sub-section-subheader'>
              We help you build your business by elevating your story and speaking. If you have ever been in a crowd and thought, "I wish I could
              speak like that," or "I wish I could tell a story like that," we're here to tell you, <strong>that's not luck.</strong> Pat Quinn has
              spent a lifetime gathering the tools and techniques to help you tell your story and speak with confidence.
            </div>
          </div>

          <div className='landing-sub-section'>
            <div className='landing-sub-section-header'>How do we do it?</div>
            <div className='landing-sub-section-subheader'>
              Pat has broken down each part of the process into a series of steps that you can follow to build your story one small piece at a time.
              Learning at your own pace, you will have unlimited access to Pat's trainings as well as a tool to compile it all into a presentation at
              the end.
            </div>
          </div>

          <div className='landing-sub-section'>
            <div className='landing-sub-section-header'>What else is there?</div>
            <div className='landing-sub-section-subheader'>
              In addition to our flagship patented Presentation Builder, we provide a number of other tools to help you build your business. Join us
              monthly for a LIVE training with Pat and other experts in the field. Take advantage of our note taking, podcast training, and curated
              perks to help you build your business.
            </div>
          </div>
        </div>

        <div className='landing-section landing-side-by-side' id='save-your-story'>
          <div className='side-by-side-image-container'>
            <img src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/save_your_stories.svg' alt='presentation_structure' />
          </div>
          <div className='side-by-side-text-container'>
            <div className='side-by-side-text-header'>Save Your Stories</div>
            <div className='side-by-side-text-subheader'>
              We all have stories that we tell over and over again. With StoryLinq, you can save your stories and have them ready to go at a moment's
              notice. Mix and match different stories to create a unique presentation for any situation. You'll never be caught off guard again.
            </div>
          </div>
        </div>

        <div className='landing-section landing-side-by-side' id='save-your-story'>
          <div className='side-by-side-text-container'>
            <div className='side-by-side-text-header'>Master. Everything.</div>
            <div className='side-by-side-text-subheader'>
              We cover every part of your presentation process. Each live training with Pat covers a different aspect of your presentation. What books
              should you read? What if you're nervous? How do you get your audience to take action? We cover it all and more, ask us anything.
            </div>
          </div>
          <div className='side-by-side-image-container'>
            <img src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/training_videos.svg' alt='presentation_structure' />
          </div>
        </div>

        <div className='landing-section landing-side-by-side' id='save-your-story'>
          <div className='side-by-side-image-container'>
            <img src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/own_pace.svg' alt='presentation_structure' />
          </div>
          <div className='side-by-side-text-container'>
            <div className='side-by-side-text-header'>Pace Yourself</div>
            <div className='side-by-side-text-subheader'>
              We provide the content and the support system, but you are in control of your own journey. Take your time and learn at your own pace.
              We'll be here to help you every step of the way.
            </div>
          </div>
        </div>

        <div className='landing-section landing-flex'>
          <div className='landing-section-title'>Transform Your Communication</div>

          <div className='landing-sub-section'>
            <img
              src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/Structure.svg'
              alt='presentation_structure'
              className='landing-sub-section-image'
            />
            <div className='landing-sub-section-header'>Presentation Structure</div>
            <div className='landing-sub-section-subheader'>
              The structure of your presentation is the cornerstone of building a better one. Pat Quinn's 3-part presentation framework transforms
              presenting from a daunting task into a simple formula. Simply follow his organization and content instructions, and you'll be on your
              way.
            </div>
          </div>

          <div className='landing-sub-section'>
            <img
              src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/Stocks.svg'
              alt='presentation_structure'
              className='landing-sub-section-image'
            />
            <div className='landing-sub-section-header'>Convert More Customers</div>
            <div className='landing-sub-section-subheader'>
              The most important part of your business is you. This is your time to invest in yourself. Create a high converting presentation that
              will forever catch the eyes of your clients.
            </div>
          </div>

          <div className='landing-sub-section'>
            <img
              src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/Network.svg'
              alt='presentation_structure'
              className='landing-sub-section-image'
            />
            <div className='landing-sub-section-header'>Same Plan, For Anything</div>
            <div className='landing-sub-section-subheader'>
              With Pat Quinn's story breakdown, we ensure that you will be prepared for any situation that you come across. Podcasts, talkshows,
              clients, you name it, you'll be ready.
            </div>
          </div>
        </div>
        <PublicFooter />
      </div>
    </div>
  );
};

Landing.propTypes = {
  openSignupModal: PropTypes.func,
  setIsSignup: PropTypes.func
};

export default Landing;
