import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './SelectOption.scss';

/**
 * Helper component for react-select package allowing for custom rendering of options
 *
 * Example:
 *
 *  <Select
 *   ...
 *  components={{ Option: SelectOption }}
 * />
 *
 * @param {Object} props
 * @param {boolean} props.isDisabled - Whether the option is disabled
 * @param {Object} props.data - Data object passed to react-select
 * @param {string} props.data.label - Title of the option
 * @param {string} props.data.sublabel - Subtitle of the option
 * @param {string[]} props.data.sublabels - Array of subtitles of the option, each will be separated by a bullet
 * @param {Object[]} props.data.icons - Array of icons to render on the right side of the option that can be clicked (with onClick function)
 *
 * example of icons:
 * [{
 *  icon: <FontAwesomeIcon icon={faTrash} />,
 *  onClick: () => console.log('clicked trash icon')
 * }]
 */
const SelectOption = props => {
  const { data, isDisabled } = props;

  const sublabels = data.sublabel ? [data.sublabel] : data.sublabels || [];
  return (
    <div {...props.innerProps} className={cn('selection-option-outer-container', { disabled: isDisabled })}>
      <div className='title'>{data.label}</div>
      {!!sublabels.length && <div className='subtitle'>{sublabels.join(' • ')}</div>}

      {data?.icons?.length ? (
        <div className='icons'>
          {data?.icons.map(({ icon, onClick }, i) => (
            <div
              key={i}
              className='icon'
              onClick={e => {
                e.stopPropagation();
                onClick();
              }}
            >
              {icon}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

SelectOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    sublabel: PropTypes.string,
    sublabels: PropTypes.array,
    icons: PropTypes.array
  }).isRequired
};

export default SelectOption;
