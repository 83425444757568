import React from 'react';
import PropTypes from 'prop-types';
// import './Froala.scss';

import FroalaEditor from 'react-froala-wysiwyg';

const Froala = props => {
  const { content, setContent } = props;

  return (
    <div className='froala-outer' style={{ zIndex: 'auto' }}>
      <FroalaEditor tag='textarea' config={froala_config} model={content} onModelChange={setContent} />
    </div>
  );
};

Froala.propTypes = {
  content: PropTypes.string,
  setContent: PropTypes.func
};

export default Froala;

const froala_config = {
  key: 'GPD2tA17B4B5D2C1A1sGXh1WWTDSGXYOUKc1KINLe1OC1c1D-17D2E2F2A1E4F1B2B8A6D7==',
  toolbarButtons: [
    'undo',
    'redo',
    '|',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'subscript',
    'superscript',
    'outdent',
    'indent',
    'clearFormatting',
    'insertTable',
    'html'
  ],
  toolbarButtonsXS: ['undo', 'redo', '-', 'bold', 'italic', 'underline'],
  autofocus: true,
  charCounterCount: true,
  attribution: false,
  toolbarBottom: true,
  theme: 'royal'
};
