import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AccountModal.scss';

// import Select from 'react-select';
// import SelectOption from '../General/SelectOption';

import cn from 'classnames';
import _ from 'lodash';

import { getAllPlans } from '../../Api/plans';
import { useQuery, useMutation } from '@apollo/client';
import { updatePrimaryPaymentMethod, updateUser } from '../../Api/users';
import Loader from '../General/Loader';
import Modal from '../General/Modal';
// import { isAE, isAdmin } from '../../Helpers/user_helpers';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const AccountModal = props => {
  const { close, user, reSyncUser } = props;

  /**************************************************************************************/
  // Global variables and api setup
  /**************************************************************************************/

  // const aeMember = isAE(user);
  // const admin = isAdmin(user);

  const { data: plansResponse } = useQuery(getAllPlans);
  const [updatePrimaryPaymentMethodAPI] = useMutation(updatePrimaryPaymentMethod);
  const [updateUserAPI] = useMutation(updateUser, { onCompleted: reSyncUser });
  const { get_all_plans: plans = [] } = plansResponse || {};
  // const planOptions = plans.map(plan => ({ value: plan.id, label: plan.name, sublabel: plan.description }));

  const [someFieldHasChanged, setSomeFieldHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // console.log({ someFieldHasChanged });

  // user fields
  const [email, setEmail] = useState(user.email);
  const [name, setName] = useState(user.name);
  const [phone, setPhone] = useState(user.phone);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [plan, setPlan] = useState();

  // card fields
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiration, setCardExpiration] = useState('');
  const [cardCVC, setCardCVC] = useState('');

  useEffect(() => {
    if (!plans.length) return;
    const userPlan = plans?.find(plan => +plan?.id === +user?.Plan?.id);
    setPlan(userPlan);
  }, [plans, setPlan, user]);

  const resetFields = () => {
    setEmail(user.email);
    setName(user.name);
    setPhone(user.phone);
    setPassword('');
    setConfirmPassword('');
    const plan = plans.find(plan => +plan?.id === +user?.Plan?.id);
    setPlan(plan);
  };

  /**************************************************************************************/
  // Field change detection
  /**************************************************************************************/

  useEffect(() => {
    const fields = [email, name, phone, password, confirmPassword, cardName, cardNumber, cardExpiration, cardCVC];
    const originalFields = [user.email, user.name, user.phone, '', '', '', '', '', ''];
    setSomeFieldHasChanged(fields.some((field, i) => field !== originalFields[i]));
  }, [email, name, phone, password, confirmPassword, cardName, cardNumber, cardExpiration, cardCVC, setSomeFieldHasChanged, user]);

  /**************************************************************************************/
  // Saving functions
  /**************************************************************************************/

  const save = async () => {
    if (email || name || phone) updateAccountInfo();
    if (password || confirmPassword) updateAccountPassword();
    if (cardName || cardNumber || cardExpiration || cardCVC) updatePaymentMethod();
  };

  const updateAccountInfo = async () => {
    const updates = {};
    if (email) updates.email = email;
    if (name) updates.name = name;
    if (phone) updates.phone = phone;

    if (_.isEmpty(updates)) return;

    setIsSaving(true);

    try {
      await updateUserAPI({ variables: updates });
      toast.success('Account info updated');
    } catch (e) {
      toast.error('Error updating account info');
    } finally {
      setIsSaving(false);
    }
  };

  const updateAccountPassword = async () => {
    if (!password) return toast.error('Please enter a new password');
    else if (!confirmPassword) return toast.error('Please confirm your new password');
    else if (password !== confirmPassword) return toast.error('Passwords do not match');

    setIsSaving(true);

    try {
      await updateUserAPI({ variables: { password } });
      toast.success('Account password updated');
    } catch (e) {
      toast.error('Error updating account password');
    } finally {
      setIsSaving(false);
    }
  };

  const updatePaymentMethod = async () => {
    if (!cardName) return toast.error('Please enter a name for the card');
    else if (!cardNumber) return toast.error('Please enter a card number');
    else if (!cardExpiration) return toast.error('Please enter a card expiration date');
    else if (!cardCVC) return toast.error('Please enter a card CVC');

    setIsSaving(true);

    try {
      const momentDate = moment(cardExpiration);
      await updatePrimaryPaymentMethodAPI({
        variables: { cvc: cardCVC, expYear: momentDate.year(), expMonth: momentDate.month() + 1, cardNumber: cardNumber, name: cardName }
      });

      toast.success('Payment method updated');
    } catch (e) {
      toast.error('There was an error updating your payment method, Please check your information and try again');
    } finally {
      setIsSaving(false);

      // reset the fields
      setCardName('');
      setCardNumber('');
      setCardExpiration('');
      setCardCVC('');
    }
  };

  const modalButtonFix = e => e.stopPropagation();

  return (
    <Modal visible={true} close={close} className='account-modal-outer' innerClassName='account-modal-inner' showClose={true} noPadding>
      <div className='account-modal-content'>
        <div className='account-modal-header'>Your Account</div>

        <div className='account-modal-body'>
          <div className='account-modal-body-section'>
            <div className='input-container'>
              <div className='input-container-label'>Primay Email (Used For Login)</div>
              <input className='input-container-input' type='text' value={email} onChange={e => setEmail(e.target.value)} />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Name</div>
              <input className='input-container-input' type='text' value={name} onChange={e => setName(e.target.value)} />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Phone</div>
              <input className='input-container-input' type='text' value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
          </div>

          <div className='account-modal-body-section'>
            <div className='input-container'>
              <div className='input-container-label'>New Password</div>
              <input className='input-container-input' type='password' value={password} onChange={e => setPassword(e.target.value)} />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Confirm New Password</div>
              <input className='input-container-input' type='password' value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            </div>
          </div>

          <div className='account-modal-body-section'>
            <div className='input-container'>
              <div className='input-container-label'>Name on Card</div>
              <input className='input-container-input' type='text' value={cardName} onChange={e => setCardName(e.target.value)} />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Card Number</div>
              <input className='input-container-input' type='text' value={cardNumber} onChange={e => setCardNumber(e.target.value)} />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Card Expiration</div>
              <input
                className='input-container-input'
                onClick={modalButtonFix}
                type='month'
                value={cardExpiration}
                onChange={e => setCardExpiration(e.target.value)}
              />
            </div>

            <div className='input-container'>
              <div className='input-container-label'>Card CVC</div>
              <input className='input-container-input' type='text' value={cardCVC} onChange={e => setCardCVC(e.target.value)} />
            </div>

            <div className='account-modal-body-section-subtitle'>
              For security purposes, we do not show card details in your account. If you are unsure of your card details you can always add a new
              card. The new payment method will be marked as your default payment method and will be used for all future payments.
            </div>
          </div>
        </div>

        <div className={cn('account-modal-footer', { active: someFieldHasChanged })}>
          <button className='cancel-button' onClick={resetFields} disabled={!someFieldHasChanged}>
            Clear
          </button>
          <button className='save-button' onClick={save} disabled={!someFieldHasChanged}>
            {isSaving ? <Loader size={20} /> : 'Save'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

AccountModal.propTypes = {
  close: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  reSyncUser: PropTypes.func.isRequired
};

export default AccountModal;
