import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AddContentModal.scss';
import Modal from '../General/Modal';
import Loader from '../General/Loader';
import { toast } from 'react-hot-toast';

const AddContentModal = props => {
  const { close, training_id } = props;

  const [title, setTitle] = useState('');
  const [saving, setSaving] = useState(false);

  const addContent = async () => {
    if (saving) return;

    if (!training_id) return toast.error('No training id');
    else if (!title) return toast.error('Please enter a valid title');

    setSaving(true);

    try {
      await props.addContent(training_id, title);
      toast.success('Successfully created new story!');
      close();
    } catch (e) {
      console.log({ e });
      toast.error('There was an error adding your content. Please try again later.');
    } finally {
      setSaving(false);
    }

    close();
  };

  return (
    <Modal visible={true} close={close} className='add-content-modal-outer' innerClassName='add-content-modal-inner' showClose={true} smallForm>
      <div className='add-content-modal-content'>
        <div className='add-content-modal-header'>Add New Content</div>
        <div className='add-content-modal-subheader'>Add a new piece of content to your presentation</div>

        <div className='add-content-modal-form'>
          <input
            className='add-content-modal-form-input'
            value={title}
            onChange={e => setTitle(e.target.value)}
            placeholder='Enter a title for your content'
          />
        </div>

        <div className='add-content-modal-actions'>
          <button className='add-content-modal-actions-button' onClick={close}>
            Cancel
          </button>
          <button className='add-content-modal-actions-button add-content-button' onClick={addContent}>
            {saving ? <Loader size={20} /> : 'Add Content'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

AddContentModal.propTypes = {
  close: PropTypes.func.isRequired,
  training_id: PropTypes.string.isRequired,
  addContent: PropTypes.func.isRequired
};

export default AddContentModal;
