import { useEffect } from 'react';

const ScrollToTop = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100); // Adjust the delay as needed
  }, []);

  return null;
};

export default ScrollToTop;
