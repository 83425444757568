import React from 'react';

// import styles for the whole app
import './App.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import MainContainer from './Containers/MainContainer';
import Landing from './Pages/Landing/Landing';
import Dashboard from './Pages/Dashboard/Dashboard';
import TrainingPortal from './Pages/Trainings/TrainingPortal';
import PresentationBuilder from './Pages/PresentationBuilder/PresentationBuilder';
import PodcastMagic from './Pages/PodcastMagic/PodcastMagic';
import AESignup from './Pages/Landing/AESignup';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
const is_production = process.env.NODE_ENV === 'production';
const client = new ApolloClient({
  uri: is_production ? 'https://storylinq.com/graphql' : 'http://localhost:5001/graphql',
  cache: new InMemoryCache(),
  credentials: 'include'
});

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={props => (
      <MainContainer>
        <Component {...props} />
      </MainContainer>
    )}
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={props => (
      <MainContainer isProtected>
        <Component {...props} />
      </MainContainer>
    )}
  />
);

const App = () => {
  return (
    <div className='App'>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <PublicRoute exact path='/' component={Landing} />
            <PublicRoute exact path='/ae' component={AESignup} />

            <PrivateRoute exact path='/dashboard' component={Dashboard} />
            <PrivateRoute exact path='/training' component={TrainingPortal} />
            <PrivateRoute exact path='/builder' component={PresentationBuilder} />
            <PrivateRoute exact path='/podcast_magic' component={PodcastMagic} />

            <Route>
              <Redirect to='/' />
            </Route>
          </Switch>
        </Router>
      </ApolloProvider>
    </div>
  );
};

export default App;
