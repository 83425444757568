import React, { useState } from 'react';
import './AESignup.scss';
import PropTypes from 'prop-types';

import { useMutation } from '@apollo/client';
import { registerUser } from '../../Api/users';
import Loader from '../../Components/General/Loader';
import { toast } from 'react-hot-toast';

const AESignup = props => {
  const { reSyncUser, openSignupModal } = props;
  const [registerAPI] = useMutation(registerUser, { onCompleted: reSyncUser });

  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const submit = async () => {
    if (!email) return toast.error('Please enter your email');
    else if (!password) return toast.error('Please enter your password');
    else if (!confirmPassword) return toast.error('Please confirm your password');
    else if (password !== confirmPassword) return toast.error('Your passwords do not match');

    setIsSaving(true);
    const signupData = { email, password, name, phone, partnershipToken: 'AE' };

    try {
      await registerAPI({ variables: { ...signupData } });
    } catch (e) {
      const message = e.message || 'There was an error creating your account. Please contact us for support.';
      return toast.error(message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className='ae-signup-outer'>
      <div className='ae-signup-inner'>
        <div className='welcome-container'>
          <div className='welcome-title'>Welcome to StoryLinq!</div>
          <div className='welcome-subtitle'>We are so excited to have you join us. Just fill in a couple fields and you'll be on your way!</div>
        </div>

        <div className='form-container'>
          <div className='form-container-title'>Account Info</div>
          <div className='input-container'>
            <input className='input-container-input' placeholder='name' type='text' value={name} onChange={e => setName(e.target.value)} />
          </div>
          <div className='input-container'>
            <input className='input-container-input' placeholder='email' type='text' value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='input-container'>
            <input className='input-container-input' placeholder='phone' type='text' value={phone} onChange={e => setPhone(e.target.value)} />
          </div>
          <div className='input-container'>
            <input
              className='input-container-input'
              placeholder='create a password'
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className='input-container'>
            <input
              className='input-container-input'
              placeholder='confirm your password'
              type='password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>

          <div className='form-container-buttons'>
            <button className='signup-button' onClick={submit}>
              {isSaving ? <Loader size={20} /> : 'Sign Up'}
            </button>
          </div>

          <div className='disclaimer'>
            Already have an account? <span onClick={openSignupModal}>Login</span>
          </div>

          <div className='disclaimer'>
            This is a sofware tool partnered with Advisors Excel, and therefore only available to Advisors Excel members. If you are not an Advisors
            Excel agent, please visit <a href='https://storylinq.com'>StoryLinq.com</a> instead.
          </div>
        </div>

        <div className='ae-logo-container'>
          <img src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Landing/advisors_excel_logo.png' alt='advisors-excel' />
        </div>
      </div>
    </div>
  );
};

AESignup.propTypes = {
  reSyncUser: PropTypes.func.isRequired,
  openSignupModal: PropTypes.func.isRequired
};

export default AESignup;
