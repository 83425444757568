export const isAdmin = user => user && user.isAdmin;
export const isAE = user => user && user.isAE;

export const getPlanId = user => user && user.plan_id;
export const getUserUpgradePurchases = user => user && user.UpgradePurchases;

export const getUserHasAccessToPodcastMagic = user => {
  const upgrades = getUserUpgradePurchases(user);
  const hasPodcastMagic = upgrades?.some(upgrade => upgrade?.Upgrade?.title === 'Podcast Magic');
  return hasPodcastMagic || isAdmin(user) || isAE(user);
  // return false;
};

/**
 * @param {Object} user
 * @param {'PERKS' | 'TRAINING'} type
 */
export const getUserHasAccessByType = (user, type) => {
  const planId = getPlanId(user);
  if (!planId) return false;

  if (type === 'PERKS' && planId > 5) return true;
  return false;
};
