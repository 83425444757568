import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './DownloadContentModal.scss';
import Modal from '../General/Modal';
import { toast } from 'react-hot-toast';
import { Document, Paragraph, Packer } from 'docx';

import Select from 'react-select';
import SelectOption from '../General/SelectOption';
import { extractTextFromHtml } from '../../Helpers/content_helpers';

const DownloadContentModal = props => {
  const { close, trainingSections } = props;

  const [selectedContentMap, setSelectedContentMap] = useState({});

  const addNewContentToContentMap = option => {
    const { value: training_id } = option;
    const { content } = option?.fullItem || {};

    const currentContentMap = { ...selectedContentMap };
    if (!content) delete currentContentMap[training_id];
    else currentContentMap[training_id] = content;

    setSelectedContentMap(currentContentMap);
  };

  const download = async () => {
    const content = Object.values(selectedContentMap).flat();
    if (!content.length) return toast.error('You must select at least one piece of content to download');

    const sections = [{ properties: {}, children: [] }];

    content.forEach(text => {
      if (text === undefined) {
        console.error('Problematic text:', text);
        return; // Skip this iteration
      }

      const extractedText = extractTextFromHtml(`<p>${text}</p>`);
      sections[0].children.push(new Paragraph(extractedText));

      // Add a blank line between each paragraph
      sections[0].children.push(new Paragraph(''));
      sections[0].children.push(new Paragraph(''));
    });

    const doc = new Document({ sections });

    const blob = await Packer.toBlob(doc);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'presentation.docx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success('Successfully downloaded your presentation');
    close();
  };

  return (
    <Modal visible={true} close={close} className='download-content-modal-outer' innerClassName='download-content-modal-inner' showClose={true}>
      <div className='download-content-modal-content'>
        <div className='download-content-modal-header'>Download your presentation</div>
        <div className='download-content-modal-subheader'>
          When you feel like you have put together everything that you need for you presentation, come here and select all the options that you want.
          Mix and match the content to whatever works for you.
        </div>

        <div className='download-content-modal-form'>
          {trainingSections.map(training => {
            const { Content: content, id: training_id, title } = training;

            if (!content.length) return null;
            return (
              <div className='download-content-modal-form-training'>
                <div className='download-content-modal-form-training-title'>{title}</div>
                <Select
                  className='download-content-modal-form-training-select'
                  options={content.map(item => ({ value: training_id, label: item.title, fullItem: item }))}
                  onChange={addNewContentToContentMap}
                  placeholder='Select content'
                  components={{ Option: SelectOption }}
                />
              </div>
            );
          })}
        </div>

        <div className='download-content-modal-actions'>
          <button className='download-content-modal-actions-button' onClick={close}>
            Cancel
          </button>
          <button className='download-content-modal-actions-button download-button' onClick={download}>
            Download
          </button>
        </div>
      </div>
    </Modal>
  );
};

DownloadContentModal.propTypes = {
  close: PropTypes.func.isRequired,
  trainingSections: PropTypes.array.isRequired
};

export default DownloadContentModal;
