import React, { Component } from 'react';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Loader.scss';

import loaderAnimationInverted from '../../Static/animations/loader_inverted.json';
import loaderAnimation from '../../Static/animations/loader.json';

class Loader extends Component {
  static propTypes = {
    size: PropTypes.number,
    message: PropTypes.string,
    inverted: PropTypes.bool
  };

  componentDidMount() {
    lottie.loadAnimation({
      container: this.loaderContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: this.props.inverted ? loaderAnimationInverted : loaderAnimation
    });
  }

  render() {
    const { size, message } = this.props;
    return (
      <div
        className={classnames('loader', 'loader-outer-container', {
          'has-message': !!message
        })}
      >
        <div style={{ width: size || 120, height: size || 120 }} ref={ref => (this.loaderContainer = ref)}></div>
        {message && <div className='loader-message'>{message}</div>}
      </div>
    );
  }
}

export default Loader;
