import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PodcastMagic.scss';

import { useQuery } from '@apollo/client';
import { getTrainings } from '../../Api/trainings';
import cn from 'classnames';

const PodcastMagic = props => {
  const { data, loading } = useQuery(getTrainings, { variables: { type: 'PODCAST_MAGIC' } });
  const trainings = data?.get_trainings?.trainings;

  const [curTraining, setCurTraining] = useState();
  const { url, title, id } = curTraining || {};

  useEffect(() => {
    if (loading) return;
    else if (trainings?.length) setCurTraining(trainings[0]);
  }, [loading, trainings, setCurTraining]);

  if (!curTraining) return null;
  return (
    <div className='podcast-magic-outer'>
      <div className='podcast-magic-inner'>
        <div className='podcast-magic-title'>Podcast Magic</div>
        <div className='podcast-magic-subtitle'>
          <div className='podcast-magic-subtitle-text'>
            Learn how to create a podcast that will help you grow your business and your brand. When you feel like you are ready, click{' '}
            <span>here</span> to let us know you are ready to launch your podcast and we will help you get it done.
          </div>
        </div>
        <div className='tab-container'>
          {trainings.map(training => (
            <div key={training.id} className={cn('tab', { active: curTraining.id === training.id })} onClick={() => setCurTraining(training)}>
              <span>{training.title}</span>
            </div>
          ))}
        </div>
        <div className='podcast-magic-training'>
          <iframe src={url} title={`${title}-${id}`} allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
};

PodcastMagic.propTypes = {
  user: PropTypes.object
};

export default PodcastMagic;
