import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './HelpModal.scss';
import Modal from './Modal';
import { toast } from 'react-hot-toast';

import { useMutation } from '@apollo/client';
import { sendEmail } from '../../Api/emails';
import Loader from './Loader';

const HelpModal = props => {
  const { close, user } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [email, setEmail] = useState(user?.email || '');
  const [emailContent, setEmailContent] = useState('');

  const [sendEmailAPI] = useMutation(sendEmail);

  const submit = async () => {
    if (!email) return toast.error('Please enter your email address');
    else if (!emailContent) return toast.error('Please enter a message before submitting.');

    setIsSaving(true);

    const fullEmailContent = `Email: ${email}\n\n${emailContent}`;
    const emailData = { subject: 'Help Request', text: fullEmailContent, to: 'info@storylinq.com' };
    console.log({ emailData });

    try {
      await sendEmailAPI({ variables: { ...emailData } });
      toast.success('Your email has been sent.');
    } catch (e) {
      console.log({ e });
      toast.error('There was an error sending your email. Please try again later.');
    }

    setIsSaving(false);
    close();
  };

  return (
    <Modal visible={true} close={close} className='help-modal-outer' innerClassName='help-modal-inner' showClose={true} smallForm>
      <div className='help-modal-content'>
        <div className='help-modal-header'>
          <div className='help-modal-title'>Need Help?</div>
          <div className='help-modal-subtitle'>Feel free to reach out to us with any questions you have. We are always happy to help.</div>

          <div className='help-modal-form'>
            <input className='help-modal-input' placeholder='Email Address' value={email} onChange={e => setEmail(e.target.value)} />
            <textarea
              className='help-modal-textarea'
              placeholder='What can we help you with?'
              value={emailContent}
              onChange={e => setEmailContent(e.target.value)}
            />
          </div>

          <div className='help-modal-submit' onClick={submit}>
            {isSaving ? <Loader size={20} /> : 'Submit'}
          </div>
        </div>
      </div>
    </Modal>
  );
};

HelpModal.propTypes = {
  user: PropTypes.object,
  close: PropTypes.func.isRequired
};

export default HelpModal;
