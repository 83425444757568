import { gql } from '@apollo/client';

export const getAllPlans = gql`
  query Get_all_plans {
    get_all_plans {
      id
      createdAt
      updatedAt
      name
      description
      plan_id
      price_id
      price_description
      type
    }
  }
`;
