import React from 'react';
import PropTypes from 'prop-types';
import './TrainingPortalTraining.scss';
import moment from 'moment';

const TrainingPortalTraining = props => {
  const { training, hasAEPrivileges } = props;
  const { title, description, date, isAE, type, url, createdAt, coverImage } = training;
  const formattedDate = +date ? moment(+date).format('MMMM Do YYYY') : moment(createdAt).format('MMMM Do YYYY');
  const shouldDisplayDate = type === 'LIVE';

  const trainingIsAE = isAE || type === 'AE';
  if (trainingIsAE && !hasAEPrivileges) return null;

  return (
    <div className='training-portal-training-outer'>
      <div className='training-portal-training-inner'>
        <a href={url} target='_blank' rel='noopener noreferrer'>
          {coverImage && <img className='training-portal-training-image' src={coverImage} alt='cover' />}
          <div className='training-portal-training-title'>
            {title}
            {shouldDisplayDate && <div className='training-portal-training-subtitle'>{formattedDate}</div>}
          </div>
          <div className='training-portal-training-description'>{description}</div>
        </a>
      </div>
    </div>
  );
};

TrainingPortalTraining.propTypes = {
  training: PropTypes.object.isRequired,
  hasAEPrivileges: PropTypes.bool.isRequired
};

export default TrainingPortalTraining;
