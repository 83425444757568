import { gql } from '@apollo/client';

const fullUpgradeObject = `
  id
  createdAt
  updatedAt
  title
  description
  price
  isHidden
  stripe_price_id
  stripe_product_id
`;

export const getUpgrades = gql`
  query Fetch_all_upgrades {
    fetch_all_upgrades {
      ${fullUpgradeObject}
    }
  }
`;

export const purchaseUpgrade = gql`
  mutation Purchase_upgrade($upgradeId: Float!, $purchaseInfo: String) {
    purchase_upgrade(upgrade_id: $upgradeId, purchaseInfo: $purchaseInfo) {
      id
      createdAt
      updatedAt
      user_id
      upgrade_id
      Upgrade {
        id
        createdAt
        updatedAt
        title
        description
        price
        isHidden
        stripe_product_id
        stripe_price_id
        purchaseInfo
      }
    }
  }
`;
