import React from 'react';
import './StorylinqAnimatedText.scss';

const StorylinqAnimatedText = () => {
  return (
    <svg id='logo' className='storylinq-animated-text' viewBox='0 0 674 145' xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1' fill='white'>
        <path d='M56.1 36.5C54.5 28 48.5 23.75 38.1 23.75C33 23.75 29 24.8 26.1 26.9C23.3 28.9 21.9 31.35 21.9 34.25C21.9 37.15 22.8 39.45 24.6 41.15C26.5 42.75 29.4 44.25 33.3 45.65L54.45 53.9C61.65 56.7 67.2 60.25 71.1 64.55C75 68.75 76.95 74.05 76.95 80.45C76.95 89.25 73.65 96.25 67.05 101.45C60.45 106.55 51.05 109.1 38.85 109.1C28.05 109.1 19.3 106.45 12.6 101.15C5.1 95.35 1.15 87.15 0.75 76.55H18.3C18.3 84.55 22.45 89.6 30.75 91.7C33.25 92.4 36.55 92.75 40.65 92.75C44.75 92.75 48.55 91.75 52.05 89.75C55.55 87.65 57.3 84.9 57.3 81.5C57.3 78 56.35 75.35 54.45 73.55C52.55 71.65 49.45 69.9 45.15 68.3L25.8 60.65C18.3 57.75 12.55 54.35 8.55 50.45C4.55 46.55 2.55 41.15 2.55 34.25C2.55 26.15 5.7 19.75 12 15.05C18.4 10.15 27.05 7.7 37.95 7.7C48.95 7.7 57.4 10.25 63.3 15.35C69.3 20.35 72.75 27.4 73.65 36.5H56.1Z' />
        <path d='M141.704 105.2C135.104 107.8 129.904 109.1 126.104 109.1C122.304 109.1 118.954 108.65 116.054 107.75C113.254 106.85 110.754 105.5 108.554 103.7C103.954 99.6 101.654 93.95 101.654 86.75V45.95H91.4543V34.1H101.654V14.9H119.354V34.1H139.154V45.95H119.354V86.15C119.354 92.15 122.204 95.15 127.904 95.15C130.804 95.15 134.554 94.35 139.154 92.75L141.704 105.2Z' />
        <path d='M188.434 32.15C193.334 32.15 197.934 32.95 202.234 34.55C206.534 36.05 210.234 38.4 213.334 41.6C220.234 48.6 223.684 58.3 223.684 70.7C223.684 82.9 220.234 92.5 213.334 99.5C207.134 105.9 198.834 109.1 188.434 109.1C178.034 109.1 169.634 105.9 163.234 99.5C156.334 92.6 152.884 83 152.884 70.7C152.884 58.2 156.334 48.5 163.234 41.6C169.534 35.3 177.934 32.15 188.434 32.15ZM205.984 70.55C205.984 62.85 204.484 57.1 201.484 53.3C198.484 49.4 194.284 47.45 188.884 47.45C183.584 47.45 179.234 49.4 175.834 53.3C172.434 57.1 170.734 62.85 170.734 70.55C170.734 78.25 172.234 84.05 175.234 87.95C178.334 91.75 182.534 93.65 187.834 93.65C193.134 93.65 197.484 91.75 200.884 87.95C204.284 84.05 205.984 78.25 205.984 70.55Z' />
        <path d='M262.445 48.5C266.845 38 273.445 32.75 282.245 32.75C283.945 32.75 286.095 33.1 288.695 33.8L288.395 51.5C285.595 50.9 282.845 50.6 280.145 50.6C268.345 50.6 262.445 58.75 262.445 75.05V107H244.745V33.95H262.445V48.5Z' />
        <path d='M316.147 34.1L333.697 86.6L348.997 34.1H366.997L339.097 120.2C336.197 128.9 332.647 135.15 328.447 138.95C324.347 142.75 319.097 144.65 312.697 144.65C306.197 144.65 299.697 143.05 293.197 139.85V124.25C298.397 127.55 303.647 129.2 308.947 129.2C312.447 129.2 315.297 127.8 317.497 125C319.797 122.2 321.647 118.55 323.047 114.05L325.297 107L296.047 34.1H316.147Z' />
        <path d='M400.433 91.55H440.483V107H382.733V9.65001H400.433V91.55Z' />
        <path d='M458.354 33.95H476.054V107H458.354V33.95ZM456.254 10.55C456.254 7.35001 457.204 4.85001 459.104 3.05001C461.004 1.15001 463.704 0.200012 467.204 0.200012C470.604 0.200012 473.254 1.15001 475.154 3.05001C477.154 4.85001 478.154 7.35001 478.154 10.55C478.154 13.75 477.154 16.3 475.154 18.2C473.254 20 470.604 20.9 467.204 20.9C463.704 20.9 461.004 20 459.104 18.2C457.204 16.3 456.254 13.75 456.254 10.55Z' />
        <path d='M523.473 46.7C528.273 37 535.973 32.15 546.573 32.15C555.573 32.15 562.323 35.1 566.823 41C571.423 46.9 573.723 54.7 573.723 64.4V107H556.023V64.4C556.023 59 554.723 54.95 552.123 52.25C549.523 49.45 545.873 48.05 541.173 48.05C536.473 48.05 532.423 50.15 529.023 54.35C525.723 58.55 523.973 64.85 523.773 73.25V107H506.073V34.1H521.523L523.473 46.7Z' />
        <path d='M645.28 97.55C641.68 105.25 635.48 109.1 626.68 109.1C617.28 109.1 609.38 105.7 602.98 98.9C596.08 91.7 592.63 82.25 592.63 70.55C592.63 59.05 595.98 49.65 602.68 42.35C608.98 35.55 616.68 32.15 625.78 32.15C630.28 32.15 634.43 33.25 638.23 35.45C642.13 37.55 645.28 40.85 647.68 45.35L651.58 34.1H663.13V118.25C663.13 122.55 663.63 125.4 664.63 126.8C665.73 128.2 666.93 129.2 668.23 129.8C669.53 130.5 671.13 131.15 673.03 131.75L667.93 144.65C659.73 142.65 653.88 139.45 650.38 135.05C646.98 130.65 645.28 124.6 645.28 116.9V97.55ZM645.28 65.3C645.28 59.8 643.68 55.55 640.48 52.55C637.28 49.55 633.18 48.05 628.18 48.05C623.28 48.05 619.08 50.05 615.58 54.05C612.18 58.05 610.48 63.55 610.48 70.55C610.48 77.65 612.48 83.3 616.48 87.5C619.98 91.2 624.23 93.05 629.23 93.05C633.33 93.05 636.93 91.8 640.03 89.3C643.33 86.5 645.08 82.85 645.28 78.35V65.3Z' />
      </mask>
      <path
        d='M56.1 36.5C54.5 28 48.5 23.75 38.1 23.75C33 23.75 29 24.8 26.1 26.9C23.3 28.9 21.9 31.35 21.9 34.25C21.9 37.15 22.8 39.45 24.6 41.15C26.5 42.75 29.4 44.25 33.3 45.65L54.45 53.9C61.65 56.7 67.2 60.25 71.1 64.55C75 68.75 76.95 74.05 76.95 80.45C76.95 89.25 73.65 96.25 67.05 101.45C60.45 106.55 51.05 109.1 38.85 109.1C28.05 109.1 19.3 106.45 12.6 101.15C5.1 95.35 1.15 87.15 0.75 76.55H18.3C18.3 84.55 22.45 89.6 30.75 91.7C33.25 92.4 36.55 92.75 40.65 92.75C44.75 92.75 48.55 91.75 52.05 89.75C55.55 87.65 57.3 84.9 57.3 81.5C57.3 78 56.35 75.35 54.45 73.55C52.55 71.65 49.45 69.9 45.15 68.3L25.8 60.65C18.3 57.75 12.55 54.35 8.55 50.45C4.55 46.55 2.55 41.15 2.55 34.25C2.55 26.15 5.7 19.75 12 15.05C18.4 10.15 27.05 7.7 37.95 7.7C48.95 7.7 57.4 10.25 63.3 15.35C69.3 20.35 72.75 27.4 73.65 36.5H56.1Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M141.704 105.2C135.104 107.8 129.904 109.1 126.104 109.1C122.304 109.1 118.954 108.65 116.054 107.75C113.254 106.85 110.754 105.5 108.554 103.7C103.954 99.6 101.654 93.95 101.654 86.75V45.95H91.4543V34.1H101.654V14.9H119.354V34.1H139.154V45.95H119.354V86.15C119.354 92.15 122.204 95.15 127.904 95.15C130.804 95.15 134.554 94.35 139.154 92.75L141.704 105.2Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M188.434 32.15C193.334 32.15 197.934 32.95 202.234 34.55C206.534 36.05 210.234 38.4 213.334 41.6C220.234 48.6 223.684 58.3 223.684 70.7C223.684 82.9 220.234 92.5 213.334 99.5C207.134 105.9 198.834 109.1 188.434 109.1C178.034 109.1 169.634 105.9 163.234 99.5C156.334 92.6 152.884 83 152.884 70.7C152.884 58.2 156.334 48.5 163.234 41.6C169.534 35.3 177.934 32.15 188.434 32.15ZM205.984 70.55C205.984 62.85 204.484 57.1 201.484 53.3C198.484 49.4 194.284 47.45 188.884 47.45C183.584 47.45 179.234 49.4 175.834 53.3C172.434 57.1 170.734 62.85 170.734 70.55C170.734 78.25 172.234 84.05 175.234 87.95C178.334 91.75 182.534 93.65 187.834 93.65C193.134 93.65 197.484 91.75 200.884 87.95C204.284 84.05 205.984 78.25 205.984 70.55Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M262.445 48.5C266.845 38 273.445 32.75 282.245 32.75C283.945 32.75 286.095 33.1 288.695 33.8L288.395 51.5C285.595 50.9 282.845 50.6 280.145 50.6C268.345 50.6 262.445 58.75 262.445 75.05V107H244.745V33.95H262.445V48.5Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M316.147 34.1L333.697 86.6L348.997 34.1H366.997L339.097 120.2C336.197 128.9 332.647 135.15 328.447 138.95C324.347 142.75 319.097 144.65 312.697 144.65C306.197 144.65 299.697 143.05 293.197 139.85V124.25C298.397 127.55 303.647 129.2 308.947 129.2C312.447 129.2 315.297 127.8 317.497 125C319.797 122.2 321.647 118.55 323.047 114.05L325.297 107L296.047 34.1H316.147Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path d='M400.433 91.55H440.483V107H382.733V9.65001H400.433V91.55Z' stroke='white' strokeWidth='4' mask='url(#path-1-inside-1)' />
      <path
        d='M458.354 33.95H476.054V107H458.354V33.95ZM456.254 10.55C456.254 7.35001 457.204 4.85001 459.104 3.05001C461.004 1.15001 463.704 0.200012 467.204 0.200012C470.604 0.200012 473.254 1.15001 475.154 3.05001C477.154 4.85001 478.154 7.35001 478.154 10.55C478.154 13.75 477.154 16.3 475.154 18.2C473.254 20 470.604 20.9 467.204 20.9C463.704 20.9 461.004 20 459.104 18.2C457.204 16.3 456.254 13.75 456.254 10.55Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M523.473 46.7C528.273 37 535.973 32.15 546.573 32.15C555.573 32.15 562.323 35.1 566.823 41C571.423 46.9 573.723 54.7 573.723 64.4V107H556.023V64.4C556.023 59 554.723 54.95 552.123 52.25C549.523 49.45 545.873 48.05 541.173 48.05C536.473 48.05 532.423 50.15 529.023 54.35C525.723 58.55 523.973 64.85 523.773 73.25V107H506.073V34.1H521.523L523.473 46.7Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
      <path
        d='M645.28 97.55C641.68 105.25 635.48 109.1 626.68 109.1C617.28 109.1 609.38 105.7 602.98 98.9C596.08 91.7 592.63 82.25 592.63 70.55C592.63 59.05 595.98 49.65 602.68 42.35C608.98 35.55 616.68 32.15 625.78 32.15C630.28 32.15 634.43 33.25 638.23 35.45C642.13 37.55 645.28 40.85 647.68 45.35L651.58 34.1H663.13V118.25C663.13 122.55 663.63 125.4 664.63 126.8C665.73 128.2 666.93 129.2 668.23 129.8C669.53 130.5 671.13 131.15 673.03 131.75L667.93 144.65C659.73 142.65 653.88 139.45 650.38 135.05C646.98 130.65 645.28 124.6 645.28 116.9V97.55ZM645.28 65.3C645.28 59.8 643.68 55.55 640.48 52.55C637.28 49.55 633.18 48.05 628.18 48.05C623.28 48.05 619.08 50.05 615.58 54.05C612.18 58.05 610.48 63.55 610.48 70.55C610.48 77.65 612.48 83.3 616.48 87.5C619.98 91.2 624.23 93.05 629.23 93.05C633.33 93.05 636.93 91.8 640.03 89.3C643.33 86.5 645.08 82.85 645.28 78.35V65.3Z'
        stroke='white'
        strokeWidth='4'
        mask='url(#path-1-inside-1)'
      />
    </svg>
  );
};

export default StorylinqAnimatedText;
