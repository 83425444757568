import React from 'react';
import PropTypes from 'prop-types';
import './Dashboard.scss';
import { isAE, isAdmin } from '../../Helpers/user_helpers';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { getTrainings } from '../../Api/trainings';

const Dashboard = props => {
  const { user, openAccountModal, openHelpModal } = props;
  const { data } = useQuery(getTrainings, { variables: { type: 'LIVE' } });
  const liveTrainings = data?.get_trainings?.trainings;

  const { name, email } = user || {};

  const adminPrivileges = isAdmin(user);
  const AEPrivileges = isAE(user);

  return (
    <div className='dashboard-outer'>
      <div className='dashboard-inner'>
        <div className='dashboard-section'>
          <div className='dashboard-title'>Your Dashboard</div>
          <div className='dashboard-subtitle'>Welcome to StoryLinq, {name || email}!</div>
        </div>

        {!isAE(user) && (
          <div className='dashboard-section' id='live-trainings'>
            <div className='dashboard-section-title'>Catch The Next Live Training</div>

            <div className='live-training-container'>
              {!isAE(user) &&
                liveTrainings?.map(training => {
                  const { id, title, description, date, url, coverImage } = training;
                  const formattedDate = +date ? moment(+date).format('MMMM Do YYYY') : null;

                  return (
                    <div className='live-training' key={`live-training-${id}`}>
                      <img className='live-training-image' src={coverImage} alt='cover' />
                      <div className='live-training-text-container'>
                        <div className='live-training-title'>
                          {title}
                          <div className='live-training-date'>{formattedDate}, 8:00pm EST</div>
                        </div>
                        <div className='live-training-description'>{description}</div>
                        <a href={url} target='_blank' rel='noopener noreferrer'>
                          <div className='live-training-button'>Join Live Training</div>
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        <div className='dashboard-section'>
          <div className='dashboard-section-title'>Presentation Building</div>
          <div className='dashboard-section-card-container'>
            <a href='/builder' className='dashboard-section-card'>
              <img
                className='dashboard-section-card-image'
                src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/StoryArtboard_1.svg'
                alt='puzzle'
              />
              <div className='image-overlay'>
                <div className='dashboard-section-card-title'>Presentation Builder</div>
                <div className='dashboard-section-card-subtitle'>
                  Go step by step through the StoryLinq process to build your presentation. Watch each lesson from Pat and fill out your story as you
                  go.
                </div>
              </div>
            </a>
            {/* <div className='dashboard-section-card'>
              <img
                className='dashboard-section-card-image'
                src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/NotesArtboard_1.svg'
                alt='puzzle'
              />
              <div className='image-overlay'>
                <div className='dashboard-section-card-title'>Jot Down Notes</div>
                <div className='dashboard-section-card-subtitle'>
                  Unlimited. Notes. Forever. Never lose a thought again, use this whenever listening to a podcast, reading a book, or watching a
                  video.
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className='dashboard-section'>
          <div className='dashboard-section-title'>Trainings</div>
          <div className='dashboard-section-card-container'>
            <a href='/training' className='dashboard-section-card'>
              <img
                className='dashboard-section-card-image'
                src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/StoryArtboard_1.svg'
                alt='puzzle'
              />
              <div className='image-overlay'>
                <div className='dashboard-section-card-title'>Training Portal</div>
                <div className='dashboard-section-card-subtitle'>Watch all of our pre-recorded content as well as our recorded live trainings.</div>
              </div>
            </a>
            {(AEPrivileges || adminPrivileges) && (
              <a href='/training?tab=ae' className='dashboard-section-card'>
                <img
                  className='dashboard-section-card-image'
                  src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/NotesArtboard_1.svg'
                  alt='puzzle'
                />
                <div className='image-overlay'>
                  <div className='dashboard-section-card-title'>AE Library</div>
                  <div className='dashboard-section-card-subtitle'>
                    Enjoy your exclusive access to the AE Library. Pat has special content recorded just for you.
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>

        <div className='dashboard-section'>
          <div className='dashboard-section-title'>Your Profile</div>
          <div className='dashboard-section-card-container'>
            <div onClick={openAccountModal} className='dashboard-section-card'>
              <img
                className='dashboard-section-card-image'
                src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/StoryArtboard_1.svg'
                alt='puzzle'
              />
              <div className='image-overlay'>
                <div className='dashboard-section-card-title'>Account Settings</div>
              </div>
            </div>
            <div onClick={openHelpModal} className='dashboard-section-card'>
              <img
                className='dashboard-section-card-image'
                src='https://storylinq-static-production.s3.us-east-2.amazonaws.com/Dashboard/NotesArtboard_1.svg'
                alt='puzzle'
              />
              <div className='image-overlay'>
                <div className='dashboard-section-card-title'>Get Help</div>
                <div className='dashboard-section-card-subtitle'>
                  If you are confused to any part of the process, please reach out to us and we will help you out.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object.isRequired,
  openAccountModal: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired
};

export default Dashboard;
