import React from 'react';
import PropTypes from 'prop-types';
import './TrainingPortalControls.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import cn from 'classnames';

const TrainingPortalControls = props => {
  const { curTab, updateTab, curSearchVal, changeSearchVal, hasAEPrivileges } = props;

  return (
    <div className='training-portal-controls-outer'>
      <div className='training-portal-controls-inner'>
        <div className='tab-container'>
          <div className={cn('tab', { active: curTab === 'past' })} onClick={() => updateTab('past')}>
            <span>Past</span>
          </div>
          <div className={cn('tab', { active: curTab === 'upcoming' })} onClick={() => updateTab('upcoming')}>
            <span>Upcoming</span>
          </div>
          {hasAEPrivileges && (
            <div className={cn('tab', { active: curTab === 'ae' })} onClick={() => updateTab('ae')}>
              <span>AE</span>
            </div>
          )}
          <div className={cn('tab', { active: curTab === 'fast_start' })} onClick={() => updateTab('fast_start')}>
            <span>Fast Start</span>
          </div>
        </div>
        <div className='search-container'>
          <input type='text' defaultValue={curSearchVal} className='gifting-search-input' onChange={changeSearchVal} placeholder='Search' />
          <FontAwesomeIcon icon={faSearch} />
        </div>
      </div>
    </div>
  );
};

TrainingPortalControls.propTypes = {
  curTab: PropTypes.string.isRequired,
  updateTab: PropTypes.func.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  changeSearchVal: PropTypes.func.isRequired,

  hasAEPrivileges: PropTypes.bool.isRequired
};

export default TrainingPortalControls;
