import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './UpgradeModal.scss';
import commaNumber from 'comma-number';
import cn from 'classnames';
import _ from 'lodash';

import { useQuery, useMutation } from '@apollo/client';
import { getUpgrades, purchaseUpgrade as purchaseUpgradeGQL } from '../../Api/upgrades';
import Loader from '../General/Loader';
import Modal from '../General/Modal';
import ConfirmPrompt from '../General/ConfirmPrompt';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const UpgradeModal = props => {
  const { reSyncUser, close, user } = props;

  const [showUpgradeSuccessOverlay, setShowUpgradeSuccessOverlay] = useState(false);

  const [prompt, setPrompt] = useState('');
  const [promptWindowVisible, setPromptWindowVisible] = useState(false);
  const openPromptWindow = () => setPromptWindowVisible(true);
  const closePromptWindow = () => setPromptWindowVisible(false);

  const [purchaseUpgradeAPI] = useMutation(purchaseUpgradeGQL, { onCompleted: reSyncUser });
  const { data } = useQuery(getUpgrades);
  const upgrades = data?.fetch_all_upgrades || [];

  const [saving, setSaving] = useState(false);
  const [selectedUpgrade, setSelectedUpgrade] = useState(null);

  const openConfirmation = async () => {
    if (!selectedUpgrade) return toast.error('Please select an upgrade to purchase');

    if (selectedUpgrade.title === 'Video Presentation Review') {
      setPrompt('In order for us to review your video presentation, we need a link to your video. Please enter the URL below.');
      openPromptWindow();
    } else {
      setPrompt('Please enter any additional information you would like us to know about your purchase. (Optional)');
      openPromptWindow();
    }
  };

  const purchaseUpgrade = async purchaseInfo => {
    setSaving(true);

    try {
      const response = await purchaseUpgradeAPI({ variables: { upgradeId: parseInt(selectedUpgrade.id), purchaseInfo } });

      setShowUpgradeSuccessOverlay(true);
      setTimeout(() => {
        setShowUpgradeSuccessOverlay(false);
        close();
      }, 10000);

      const { errors } = response;
      if (!_.isEmpty(errors)) return toast.error('There was an error purchasing your upgrade');
    } catch (e) {
      if (e?.message === 'You need to update your payment method') {
        toast.error('You need to update your payment method, please go to your account settings.');
      } else if (e?.message) toast.error(e.message);
      else toast.error('There was an error purchasing your upgrade');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal visible={true} close={close} className='upgrade-modal-outer' innerClassName='upgrade-modal-inner' showClose={true}>
      <div className='upgrade-modal-header'>
        <div className='upgrade-modal-title'>Purchase Upgrades</div>
        <div className='upgrade-modal-subtitle'>Purchase upgrades to unlock additional features and content.</div>
      </div>

      <div className='upgrade-modal-body'>
        {upgrades.map(upgrade => {
          const { id, title, description, price, isHidden } = upgrade;
          if (user?.isAE && title === 'Podcast Magic') return null;

          if (isHidden) return null;
          return (
            <div className={cn('upgrade-outer', { active: selectedUpgrade?.id === id })} key={id} onClick={() => setSelectedUpgrade(upgrade)}>
              <div className='upgrade-inner'>
                <div className='upgrade-text-container'>
                  <div className='upgrade-title'>{title}</div>
                  <div className='upgrade-description'>{description}</div>
                </div>
                <div className='upgrade-price-container'>
                  <div className='upgrade-price'>${commaNumber(price)}</div>
                  <div className='disclaimer'>One time fee</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className='upgrade-modal-footer'>
        <button className='upgrade-modal-cancel-button' onClick={close}>
          Cancel
        </button>
        <button className='upgrade-modal-save-button' onClick={openConfirmation}>
          {saving ? <Loader size={20} /> : 'Save'}
        </button>
      </div>

      {promptWindowVisible && (
        <div className='upgrade-modal-prompt-window'>
          <div className='upgrade-modal-prompt-window-inner'>
            <ConfirmPrompt
              header='We Need A Little More'
              subheader={prompt}
              onSubmit={response => purchaseUpgrade(response)}
              onCancel={closePromptWindow}
              disclaimer={`By clicking submit, you agree to the terms and conditions of the upgrade and authorize StoryLinq to charge your default payment method.`}
            />
          </div>
        </div>
      )}

      <div className={cn('upgrade-modal-success-overlay', { active: showUpgradeSuccessOverlay })}>
        <div className='upgrade-modal-success-overlay-inner'>
          <div className='animated-check-mark'>
            <FontAwesomeIcon className='checkmark' icon={faCheck} />
            <div className='spiky-circle'></div>
          </div>
          <div className='upgrade-modal-success-overlay-title'>Upgrade Purchased Successfully</div>
          <div className='upgrade-modal-success-overlay-subtitle'>
            Thank you so much your transaction has been completed. Our team has received your order and will begin processing it shortly. You will
            receive email confirmations throughout the entire process. If you have any questions, please feel free to reach out to us anytime.
          </div>
          <button className='upgrade-modal-success-overlay-button' onClick={close}>
            Continue &gt;
          </button>
        </div>
      </div>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  user: PropTypes.object,
  reSyncUser: PropTypes.func,
  close: PropTypes.func.isRequired
};

export default UpgradeModal;
