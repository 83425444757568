import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TrainingPortal.scss';
import { isAE, isAdmin } from '../../Helpers/user_helpers';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { getTrainings } from '../../Api/trainings';
import * as training_helpers from '../../Helpers/training_helpers';
import TrainingPortalControls from '../../Components/TrainingPortal/TrainingPortalControls';
import TrainingPortalTraining from '../../Components/TrainingPortal/TrainingPortalTraining';

const TrainingPortal = props => {
  const { user } = props;
  const { data } = useQuery(getTrainings);
  const trainings = data?.get_trainings?.trainings;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const hasAdminPrivileges = isAdmin(user);
  const hasAEPrivileges = hasAdminPrivileges || isAE(user);

  const [curSearchVal, setCurSearchVal] = useState('');
  const defaultRoute = hasAEPrivileges ? 'ae' : 'past';
  const [curTab, setCurTab] = useState(queryParams.get('tab') || defaultRoute); // upcoming, past, ae, fast_start

  const updateTab = tab => {
    if (tab === 'ae' && !hasAEPrivileges) return;
    window.history.replaceState(null, null, `?tab=${tab}`);
    setCurTab(tab);
  };

  const changeSearchVal = e => setCurSearchVal(e.target.value);

  const pastTrainings = training_helpers.getPastTrainings(trainings);
  const upcomingTrainings = training_helpers.getLiveTrainings(trainings);
  const AETrainings = training_helpers.getAETrainings(trainings);
  const fastStartTrainings = training_helpers.getFastStartTrainings(trainings);

  const getPageTitle = () => {
    if (curTab === 'past') return 'Your Training Portal';
    else if (curTab === 'upcoming') return 'Upcoming Trainings';
    else if (curTab === 'ae') return 'AE Trainings';
    else if (curTab === 'fast_start') return 'Fast Start Trainings';
  };

  const getCurrentTrainings = () => {
    if (curTab === 'past') return pastTrainings;
    else if (curTab === 'upcoming') return upcomingTrainings;
    else if (curTab === 'ae') return AETrainings;
    else if (curTab === 'fast_start') return fastStartTrainings;
  };

  const sortByNewestFirst = (a, b) => moment(b.createdAt).diff(moment(a.createdAt));
  const filterBySearchVal = training => {
    if (!curSearchVal) return true;
    const searchVal = curSearchVal.toLowerCase();
    return training?.title?.toLowerCase()?.includes(searchVal) || training?.description?.toLowerCase()?.includes(searchVal);
  };

  return (
    <div className='training-portal-outer'>
      <div className='training-portal-inner'>
        <div className='training-portal-title'>{getPageTitle()}</div>
        <TrainingPortalControls
          curTab={curTab}
          updateTab={updateTab}
          curSearchVal={curSearchVal}
          changeSearchVal={changeSearchVal}
          hasAEPrivileges={hasAEPrivileges}
        />
        <div className='trainings-container'>
          {getCurrentTrainings()
            ?.sort(sortByNewestFirst)
            ?.filter(filterBySearchVal)
            ?.map(training => (
              <TrainingPortalTraining key={`training-${training.id}`} training={training} hasAEPrivileges={hasAEPrivileges} />
            ))}
        </div>
      </div>
    </div>
  );
};

TrainingPortal.propTypes = {
  user: PropTypes.object,
  reSyncUser: PropTypes.func
};

export default TrainingPortal;
