import { gql } from '@apollo/client';

export const getTrainings = gql`
  query Get_trainings($type: String) {
    get_trainings(type: $type) {
      trainings {
        id
        createdAt
        updatedAt
        type
        title
        description
        date
        url
        place
        handout
        isAE
        coverImage
      }
      valid
    }
  }
`;
