import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PresentationBuilder.scss';

import { useQuery, useMutation } from '@apollo/client';
import { upsertContent, getTrainingsWithContent, deleteContent as deleteContentGQL } from '../../Api/content';

import PresentationBuilderTraining from '../../Components/PresentationBuilder/PresentationBuilderTraining';
import Loader from '../../Components/General/Loader';
import ScrollToTop from '../../Components/General/ScrollToTop';
import { toast } from 'react-hot-toast';
import DownloadContentModal from '../../Components/PresentationBuilder/DownloadContentModal';

const PresentationBuilder = props => {
  const { user, openUpgradeModal } = props;

  // const hasAdminPrivileges = isAdmin(user);
  // const hasAEPrivileges = isAE(user);
  // const hasAccessToPerks = getUserHasAccessByType(user, 'PERKS') || hasAdminPrivileges;

  const { loading, data, refetch: resyncContent } = useQuery(getTrainingsWithContent);
  const [upsertContentAPI] = useMutation(upsertContent);
  const [deleteContentAPI] = useMutation(deleteContentGQL);
  const [trainings, setTrainings] = useState([]);
  const [showDownloadContentModal, setShowDownloadContentModal] = useState(false);
  const openDownloadContentModal = () => setShowDownloadContentModal(true);
  const closeDownloadContentModal = () => setShowDownloadContentModal(false);

  /************************************************************/
  // LIFECYCLE
  /************************************************************/

  useEffect(() => {
    if (loading) return;
    const { get_trainings_with_user_content: trainingsWithContent } = data || {};
    setTrainings(trainingsWithContent);
  }, [data, loading]);

  /************************************************************/
  // API CALLS
  /************************************************************/

  const addContent = async (training_id, title) => {
    const { id: user_id } = user;
    const newContent = { user_id: parseInt(user_id), training_id: parseInt(training_id), title };

    try {
      const response = await upsertContentAPI({ variables: { options: newContent }, onCompleted: resyncContent });
      const newContentItem = response?.data?.upsert_content?.created;
      return newContentItem;
    } catch (e) {
      toast.error('Error creating new story');
    }
  };

  const updateContent = async (content, updates) => {
    const { id: content_id } = content;
    const updatedContent = { ...content, ...updates };

    const currentTrainings = [...trainings];
    const updatedTrainings = currentTrainings.map(training => {
      if (+content.training_id !== +training.id) return training;
      const { Content } = training;
      const updatedContent = Content.map(item => (+item.id === +content_id ? { ...item, ...updates } : item));
      return { ...training, Content: updatedContent };
    });

    setTrainings(updatedTrainings);

    const response = await upsertContentAPI({ variables: { options: { ...updatedContent, content_id } } });
    const { errors } = response;
    if (errors) {
      toast.error('There was an error saving your content');
      setTrainings(currentTrainings);
    }
  };

  const deleteContent = async content_id => {
    if (!content_id) return toast.error('Error deleting content');

    /*
      mutation Delete_user_content($contentId: Float!) {
        delete_user_content(content_id: $contentId)
      }
    */

    try {
      await deleteContentAPI({ variables: { contentId: content_id }, onCompleted: resyncContent });
      toast.success('Content successfully deleted');
    } catch (e) {
      toast.error('Error deleting your content');
    }
  };

  return (
    <div className='presentation-builder-outer'>
      <div className='presentation-builder-inner'>
        {showDownloadContentModal && <DownloadContentModal close={closeDownloadContentModal} trainingSections={trainings} />}
        <div className='presentation-builder-header'>
          <div className='presentation-builder-title'>Presentation Builder</div>
          <div className='presentation-builder-subtitle'>
            Walk through each step and save as you go. Come back anytime. Want a recording of your presentation reviewed?{' '}
            <span onClick={openUpgradeModal}>Upgrade now</span>
          </div>
          <div className='presentation-builder-subtitle'>
            <div className='presentation-builder-subtitle-download' onClick={openDownloadContentModal}>
              Download your presentation
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className='presentation-builder-trainings-container'>
            <ScrollToTop />
            {trainings?.map(training => {
              return (
                <PresentationBuilderTraining
                  key={`presentation-builder-training-${training.id}`}
                  user={user}
                  training={training}
                  addContent={addContent}
                  updateContent={updateContent}
                  deleteContent={deleteContent}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PresentationBuilder.propTypes = {
  user: PropTypes.object,
  reSyncUser: PropTypes.func,
  openUpgradeModal: PropTypes.func
};

export default PresentationBuilder;
