import React from 'react';
import PropTypes from 'prop-types';
import './Navbar.scss';
import cn from 'classnames';

import { useLocation } from 'react-router-dom';
import { isAE, isAdmin, getUserHasAccessByType, getUserHasAccessToPodcastMagic } from '../../Helpers/user_helpers';

const Navbar = props => {
  const { user, openUpgradeModal, openAccountModal, logout } = props;

  const location = useLocation();
  const { pathname } = location;

  const canViewAEContent = isAE(user) || isAdmin(user);
  const canViewHighSubscriptionContent = getUserHasAccessByType(user, 'PERKS') || isAdmin(user);
  const hasAccessToPodcastMagic = getUserHasAccessToPodcastMagic(user);

  return (
    <div className='navbar-outer'>
      <div className='navbar-inner'>
        <div className='navbar-section'>
          <div className='navbar-header'>StoryLinq</div>
        </div>

        <div className='navbar-section'>
          <a href='/dashboard' className={cn('navbar-link', { active: pathname === '/dashboard' })}>
            Dashboard
          </a>
          <a href='/training' className={cn('navbar-link', { active: pathname === '/training' })}>
            Training
          </a>
          {/* {!isAE(user) && (
            <a href='/perks' className={cn('navbar-link', { active: pathname === '/perks' })}>
              Perks
            </a>
          )} */}
          <a href='builder' className={cn('navbar-link', { active: pathname === '/builder' })}>
            Presentation Builder
          </a>
          {/* <a href='/notes' className={cn('navbar-link', { active: pathname === '/notes' })}>Notes</a> */}
        </div>

        {(canViewHighSubscriptionContent || canViewAEContent) && (
          <div className='navbar-section'>
            {hasAccessToPodcastMagic ? (
              <a href='/podcast_magic' className={cn('navbar-link', { active: pathname === '/podcast_magic' })}>
                Podcast Magic
              </a>
            ) : (
              <div className='navbar-link' onClick={openUpgradeModal}>
                Podcast Magic
              </div>
            )}
          </div>
        )}

        <div className='navbar-section'>
          <div className={cn('navbar-link')} onClick={openUpgradeModal}>
            Additional Services
          </div>
          <div className={cn('navbar-link')} onClick={openAccountModal}>
            Account
          </div>
          <div className='navbar-link' onClick={logout}>
            Logout
          </div>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  user: PropTypes.object.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  openAccountModal: PropTypes.func.isRequired,
  logout: PropTypes.func
};

export default Navbar;
