import { gql } from '@apollo/client';

const fullContentObject = `
  id
  createdAt
  updatedAt
  title
  content
  user_id
  training_id
`;

export const upsertContent = gql`
  mutation Upsert_content($options: ContentInput!) {
    upsert_content(options: $options) {
      created {
        ${fullContentObject}
      }
      valid
    }
  }
`;

export const getTrainingsWithContent = gql`
  query Get_trainings_with_user_content {
    get_trainings_with_user_content {
      id
      title
      description
      url
      Content {
        id
        createdAt
        updatedAt
        title
        content
        user_id
        training_id
        isExample
        isAE
      }
    }
  }
`;

export const deleteContent = gql`
  mutation Delete_user_content($contentId: Float!) {
    delete_user_content(content_id: $contentId)
  }
`;
