import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './MainContainer.scss';
import PropTypes from 'prop-types';

import Loader from '../Components/General/Loader';
import { Toaster } from 'react-hot-toast';

import SignupModal from '../Components/General/SignupModal';
import UpgradeModal from '../Components/UpgradeModal/UpgradeModal';
import HelpModal from '../Components/General/HelpModal';
import AccountModal from '../Components/AccountModal/AccountModal';

import { syncUser, logoutUser } from '../Api/users';
import { useQuery, useMutation } from '@apollo/client';
import Navbar from '../Components/Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import CollapsedNavbar from '../Components/Navbar/CollapsedNavbar';

const MainContainer = props => {
  const { children, isProtected } = props;

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { loading, error, data, refetch: reSyncUser } = useQuery(syncUser);
  const { sync_user: user } = data || {};

  const [logout] = useMutation(logoutUser, { onCompleted: reSyncUser });

  const signupParamPresent = !!queryParams.get('signup');
  const loginParamPresent = !!queryParams.get('login');

  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);
  const openUpgradeModal = () => setUpgradeModalVisible(true);
  const closeUpgradeModal = () => setUpgradeModalVisible(false);

  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const openHelpModal = () => setHelpModalVisible(true);
  const closeHelpModal = () => setHelpModalVisible(false);

  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const openAccountModal = () => setAccountModalVisible(true);
  const closeAccountModal = () => setAccountModalVisible(false);

  const [signupModalVisible, setSignupModalVisible] = useState(signupParamPresent || loginParamPresent);
  const [isSignup, setIsSignup] = useState(signupParamPresent);
  const openSignupModal = () => setSignupModalVisible(true);
  const closeSignupModal = () => setSignupModalVisible(false);

  const logoutCurrentUser = async () => {
    await logout();
    history.push('/');
  };

  useEffect(() => {
    if (loading) return;
    else if (!isProtected && user) history.push('/dashboard');
    else if (isProtected && !user) history.push('/?login=true');
    else if (error) history.push('/');
  }, [loading, error, user, history, isProtected]);

  if (loading) return <Loader />;
  return isProtected && user ? (
    <div className='main-container-outer'>
      <div className='main-container-inner'>
        <Toaster />
        <CollapsedNavbar user={user} openUpgradeModal={openUpgradeModal} openAccountModal={openAccountModal} logout={logout} />
        <div className='navbar-container'>
          <Navbar user={user} openUpgradeModal={openUpgradeModal} openAccountModal={openAccountModal} logout={logoutCurrentUser} />
        </div>
        <div className='content-container'>
          {React.cloneElement(children, { user, reSyncUser, openUpgradeModal, openHelpModal, openAccountModal })}
        </div>
        {upgradeModalVisible && <UpgradeModal close={closeUpgradeModal} user={user} reSyncUser={reSyncUser} />}
        {helpModalVisible && <HelpModal close={closeHelpModal} user={user} />}
        {accountModalVisible && <AccountModal close={closeAccountModal} user={user} reSyncUser={reSyncUser} />}
        <div className='open-help-modal-button' onClick={openHelpModal}>
          <FontAwesomeIcon icon={faQuestion} />
        </div>
      </div>
    </div>
  ) : (
    <div className='main-container-outer'>
      <Toaster containerClassName='storylinq-toasts' />
      {helpModalVisible && <HelpModal close={closeHelpModal} user={user} />}
      {signupModalVisible && <SignupModal close={closeSignupModal} isSignup={isSignup} setIsSignup={setIsSignup} reSyncUser={reSyncUser} />}
      {React.cloneElement(children, { openSignupModal, setIsSignup, reSyncUser, openHelpModal })}
      <div className='open-help-modal-button' onClick={openHelpModal}>
        <FontAwesomeIcon icon={faQuestion} />
      </div>
    </div>
  );
};

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isProtected: PropTypes.bool
};

export default MainContainer;
