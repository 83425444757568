import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// const is_production = process.env.NODE_ENV === 'production';

// const client = new ApolloClient({
//   uri: is_production ? 'https://storylinq.com/graphql' : 'http://localhost:5001/graphql',
//   cache: new InMemoryCache(),
//   credentials: 'include'
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <ApolloProvider client={client}>
  <App />
  // </ApolloProvider>
);
