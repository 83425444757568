import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SignupModal.scss';
import Modal from './Modal';
import Select from 'react-select';
import SelectOption from './SelectOption';
import Loader from './Loader';
import { toast } from 'react-hot-toast';

import { getAllPlans } from '../../Api/plans';
import { loginUser as loginUserAPI, registerUser as registerUserAPI } from '../../Api/users';
import { useQuery, useMutation } from '@apollo/client';
import { convertApolloErrorMessage } from '../../Helpers/api_helpers';

const SignupModal = props => {
  const { isSignup, setIsSignup, reSyncUser } = props;
  const { data } = useQuery(getAllPlans);
  const [loginUser] = useMutation(loginUserAPI, { onCompleted: reSyncUser });
  const [registerUser] = useMutation(registerUserAPI, { onCompleted: reSyncUser });
  const { get_all_plans: plans = [] } = data || {};

  const planOptions = plans.map(plan => ({ value: plan.id, label: plan.name, sublabel: plan.description }));

  const [isSaving, setIsSaving] = useState(false);

  const [isEnteringPaymentInfo, setIsEnteringPaymentInfo] = useState(false);
  const [plan, setPlan] = useState('basic');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [discountCode, setDiscountCode] = useState('');

  const [cardNumber, setCardNumber] = useState('');
  const [cardExpMonth, setCardExpMonth] = useState('');
  const [cardExpYear, setCardExpYear] = useState('');
  const [cardCvc, setCardCvc] = useState('');

  const close = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
    props.close();
  };

  const switchToLogin = () => setIsSignup(false);
  const switchToSignup = () => setIsSignup(true);

  const signup = async () => {
    if (!name) return toast.error('Please enter your name');
    else if (!email) return toast.error('Please enter your email');
    else if (!password) return toast.error('Please enter your password');

    const missingCardInfo = !cardNumber || !cardExpMonth || !cardExpYear || !cardCvc;
    if (missingCardInfo) return toast.error('Please enter your payment information');

    setIsSaving(true);
    const data = {
      planId: parseInt(plan.value),
      name,
      email,
      phone,
      password,
      discount: discountCode,
      cardNumber,
      cardExpMonth,
      cardExpYear,
      cardCvc
    };

    registerUser({ variables: { ...data } })
      .catch(e => {
        console.log({ e });
        const message = convertApolloErrorMessage(e);
        toast.error(message || 'There was an error signing up. Please try again later.');
      })
      .finally(() => {
        setIsSaving(false);
      });

    // await reSyncUser();
  };

  const login = async () => {
    if (!email) return toast.error('Please enter your email');
    else if (!password) return toast.error('Please enter your password');
    setIsSaving(true);

    try {
      const response = await loginUser({ variables: { options: { email, password } } });
      console.log({ response });
      toast.success('Successfully logged in!');
    } catch (e) {
      console.log({ e });
      const message = convertApolloErrorMessage(e);
      toast.error(message || 'There was an error logging in. Please try again later.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal visible={true} close={close} className='signup-modal-outer' innerClassName='signup-modal-inner' showClose={true} smallForm>
      <div className='signup-modal-content'>
        {isSignup ? (
          <>
            <div className='signup-modal-header'>
              Get an Account
              <div className='signup-modal-subheader'>
                Already have an account? <span onClick={switchToLogin}>Login</span>
              </div>
            </div>

            <div className='signup-modal-form'>
              {isEnteringPaymentInfo ? (
                <>
                  <div className='signup-modal-form-header'>Enter your Payment Information</div>
                  <input value={cardNumber} onChange={e => setCardNumber(e.target.value)} type='text' placeholder='Card Number' />
                  <input value={cardExpMonth} onChange={e => setCardExpMonth(e.target.value)} type='text' placeholder='MM' />
                  <input value={cardExpYear} onChange={e => setCardExpYear(e.target.value)} type='text' placeholder='YYYY' />
                  <input value={cardCvc} onChange={e => setCardCvc(e.target.value)} type='text' placeholder='CVC' />
                </>
              ) : (
                <>
                  <div className='signup-modal-form-header'>Choose a Plan</div>
                  <Select
                    className='signup-modal-select'
                    classNamePrefix='signup-modal-select'
                    options={planOptions}
                    value={plan}
                    onChange={setPlan}
                    components={{ Option: SelectOption }}
                  />

                  <div className='signup-modal-form-header'>Enter your Information</div>
                  <input value={name} onChange={e => setName(e.target.value)} type='text' placeholder='Name' />
                  <input value={email} onChange={e => setEmail(e.target.value)} type='text' placeholder='Email' />
                  <input value={phone} onChange={e => setPhone(e.target.value)} type='text' placeholder='Phone' />
                  <input value={password} onChange={e => setPassword(e.target.value)} type='password' placeholder='Password' />
                  <input value={discountCode} onChange={e => setDiscountCode(e.target.value)} type='text' placeholder='Discount Code' />
                </>
              )}
            </div>

            <div className='signup-modal-footer'>
              {isEnteringPaymentInfo ? (
                <button className='signup-button' onClick={signup}>
                  {isSaving ? <Loader size={20} /> : 'Sign Up'}
                </button>
              ) : (
                <button className='signup-button' onClick={() => setIsEnteringPaymentInfo(true)}>
                  Next
                </button>
              )}
              {isEnteringPaymentInfo ? (
                <button className='cancel-button' onClick={() => setIsEnteringPaymentInfo(false)}>
                  Back
                </button>
              ) : (
                <button className='cancel-button' onClick={close}>
                  Cancel
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <div className='signup-modal-header'>
              Login to your Account
              <div className='signup-modal-subheader'>
                Don't have an account? <span onClick={switchToSignup}>Sign Up Instead</span>
              </div>
            </div>

            <div className='signup-modal-form'>
              <div className='signup-modal-form-header'>Enter your Information</div>
              <input value={email} onChange={e => setEmail(e.target.value)} type='text' placeholder='Email' />
              <input value={password} onChange={e => setPassword(e.target.value)} type='password' placeholder='Password' />
            </div>

            <div className='signup-modal-footer'>
              <button className='login-button' onClick={login}>
                {isSaving ? <Loader size={20} /> : 'Login'}
              </button>
              <button className='cancel-button' onClick={close}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

SignupModal.propTypes = {
  close: PropTypes.func.isRequired,
  isSignup: PropTypes.bool.isRequired,
  setIsSignup: PropTypes.func.isRequired,
  reSyncUser: PropTypes.func.isRequired
};

export default SignupModal;
