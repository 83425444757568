import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PresentationBuilderTraining.scss';

import Select from 'react-select';
import SelectOption from '../General/SelectOption';

import Froala from '../General/Froala';
import AddContentModal from './AddContentModal';
import { toast } from 'react-hot-toast';
// import { extractTextFromHtml } from '../../Helpers/content_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';

const PresentationBuilderTraining = props => {
  const { user, training, updateContent } = props;
  const { Content: content } = training;

  const { id: training_id, title, url: videoUrl } = training;

  const contentOptions = [
    ...content?.map(item => ({
      value: item.id,
      label: item.title,
      // sublabel: extractTextFromHtml(item.content),
      content: item.content,
      fullItem: item,
      icons: item.isExample
        ? []
        : [
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              onClick: () => deleteContent(parseInt(item.id))
            }
          ]
    })),
    { value: null, label: 'Add New Content' }
  ];

  const getDefaultContent = () => {
    const defaultContent = contentOptions.find(item => !item?.fullItem?.isExample);
    return defaultContent || contentOptions[contentOptions.length - 1];
  };

  const [isSaving, setIsSaving] = useState(false);
  const [selectedContent, setSelectedContent] = useState(getDefaultContent());
  const [editorContent, setEditorContent] = useState(getDefaultContent().content || '');
  const [showAddContentModal, setShowAddContentModal] = useState(false);
  const openAddContentModal = () => setShowAddContentModal(true);
  const closeAddContentModal = () => setShowAddContentModal(false);
  const selectedContentIsExample = selectedContent?.fullItem?.isExample;

  const deleteContent = async contentId => {
    await props.deleteContent(contentId);
    setSelectedContent(getDefaultContent());
    setEditorContent(getDefaultContent().content || '');
  };

  const updateSelectedContent = selectedContent => {
    if (!selectedContent.value) return openAddContentModal();
    setSelectedContent(selectedContent);
    setEditorContent(selectedContent.content || '');
  };

  const handleContentChange = async content => {
    if (selectedContent?.fullItem?.isExample) return;

    setIsSaving(true);
    setTimeout(() => setIsSaving(false), 2000);
    if (!selectedContent.value) return openAddContentModal();
    setEditorContent(content);

    try {
      await updateContent(
        {
          content_id: parseInt(selectedContent.value),
          training_id: parseInt(training_id),
          user_id: parseInt(user.id),
          title: selectedContent.label
        },
        { content }
      );
    } catch (e) {
      toast.error('Error saving your content');
    }
  };

  const addContent = async (training_id, title) => {
    const newItem = await props.addContent(training_id, title);
    if (!newItem) return;
    const newContent = { value: newItem.id, label: newItem.title, sublabel: '', content: '', fullItem: newItem };
    setSelectedContent(newContent);
    setEditorContent('');
  };

  return (
    <div className='presentation-builder-training-outer'>
      <div className='presentation-builder-content-title'>{title}</div>
      <div className='presentation-builder-training-inner'>
        {showAddContentModal && <AddContentModal close={closeAddContentModal} training_id={training_id} addContent={addContent} />}
        <div className='training-video-container'>
          <iframe className='training-video' src={videoUrl} title={title} />
          <div className='saving-container'>
            {isSaving ? (
              <>
                <FontAwesomeIcon icon={faSync} spin />
                <span>saving</span>
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faCheck} />
                <span>saved</span>
              </>
            )}
          </div>
        </div>
        <div className='presentation-builder-content-container'>
          <Select
            className='presentation-builder-content-select'
            options={contentOptions}
            value={selectedContent}
            onChange={updateSelectedContent}
            components={{ Option: SelectOption }}
          />

          {selectedContentIsExample && (
            <div className='disclaimer'>
              Since this is an example, you cannot edit it. If you want to edit it, you can create a copy of it by clicking the "Add New Content"
              button below, and copying the content from this example into the new content.
            </div>
          )}

          <div className='presentation-builder-content-editor'>
            <Froala content={editorContent} setContent={handleContentChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

PresentationBuilderTraining.propTypes = {
  user: PropTypes.object,
  content: PropTypes.array,
  training: PropTypes.object,
  addContent: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired,
  deleteContent: PropTypes.func.isRequired
};

export default PresentationBuilderTraining;
