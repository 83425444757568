import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CollapsedNavbar.scss';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getUserHasAccessByType, getUserHasAccessToPodcastMagic, isAE, isAdmin } from '../../Helpers/user_helpers';

const CollapsedNavbar = props => {
  const { user, openUpgradeModal, openAccountModal, logout } = props;
  const [showMenu, setShowMenu] = useState(false);

  const canViewAEContent = isAE(user) || isAdmin(user);
  const canViewHighSubscriptionContent = getUserHasAccessByType(user, 'PERKS') || isAdmin(user);
  const hasAccessToPodcastMagic = getUserHasAccessToPodcastMagic(user);

  const openAccount = () => {
    setShowMenu(false);
    openAccountModal();
  };

  return (
    <>
      <div className='collapsed-navbar-button' onClick={() => setShowMenu(!showMenu)}>
        {showMenu ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <div className='collapsed-navbar-button-inner'>
            <div className='collapsed-navbar-button-line' />
            <div className='collapsed-navbar-button-line' />
            <div className='collapsed-navbar-button-line' />
          </div>
        )}
      </div>
      {showMenu && (
        <div className='collapsed-navbar-outer'>
          <div className='collapsed-navbar-inner'>
            <div className='collapsed-navbar-section'>
              <div className='collapsed-navbar-header'>StoryLinq</div>
            </div>

            <div className='collapsed-navbar-section'>
              <a href='/dashboard' className='collapsed-navbar-link'>
                Dashboard
              </a>
              <a href='/training' className='collapsed-navbar-link'>
                Training
              </a>
              <a href='/builder' className='collapsed-navbar-link'>
                Presentation Builder
              </a>
            </div>

            {(canViewHighSubscriptionContent || canViewAEContent) && (
              <div className='navbar-section'>
                {hasAccessToPodcastMagic ? (
                  <a href='/podcast_magic' className='navbar-link'>
                    Podcast Magic
                  </a>
                ) : (
                  <div className='navbar-link' onClick={openUpgradeModal}>
                    Podcast Magic
                  </div>
                )}
              </div>
            )}

            <div className='collapsed-navbar-section'>
              <div className='collapsed-navbar-link' onClick={openUpgradeModal}>
                Additional Services
              </div>
              <div className='collapsed-navbar-link' onClick={openAccount}>
                Account
              </div>
              <div className='collapsed-navbar-link' onClick={logout}>
                Logout
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CollapsedNavbar.propTypes = {
  user: PropTypes.object.isRequired,
  openUpgradeModal: PropTypes.func.isRequired,
  openAccountModal: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default CollapsedNavbar;
