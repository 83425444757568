import { gql } from '@apollo/client';

const fullUserObject = `
  id
  createdAt
  updatedAt
  email
  name
  phone
  discount
  status
  verified
  checkout_id
  customer_id
  plan_id
  role
  isAdmin
  isAE
  isCancelled
  isSuspended
  isAffiliate
  Plan {
    id
    createdAt
    updatedAt
    name
    description
    plan_id
    price_id
    price_description
    type
  }
  UpgradePurchases {
    id
    createdAt
    updatedAt
    user_id
    upgrade_id
    Upgrade {
      title
      createdAt
      updatedAt
      id
    }
  }
`;

export const getUsers = gql`
  query Users {
    users {
      ${fullUserObject}
    }
  }
`;

export const syncUser = gql`
  query Sync_user {
    sync_user {
      ${fullUserObject}
    }
  }
`;

export const loginUser = gql`
  mutation Login($options: LoginInput!) {
    login(options: $options) {
      errors {
        field
        message
      }
      user {
        ${fullUserObject}
      }
      valid
    }
  }
`;

export const logoutUser = gql`
  mutation Logout {
    logout {
      valid
    }
  }
`;

export const registerUser = gql`
  mutation Register_user(
    $password: String!
    $email: String!
    $cardCvc: String
    $cardExpYear: String
    $cardNumber: String
    $cardExpMonth: String
    $partnershipToken: String
    $planId: Int
    $phone: String
    $discount: String
    $name: String
  ) {
    register_user(
      password: $password
      email: $email
      cardCvc: $cardCvc
      cardExpYear: $cardExpYear
      cardNumber: $cardNumber
      cardExpMonth: $cardExpMonth
      partnershipToken: $partnershipToken
      plan_id: $planId
      phone: $phone
      discount: $discount
      name: $name
    ) {
      id
      createdAt
      updatedAt
      email
      name
      phone
      discount
      status
      verified
      checkout_id
      customer_id
      plan_id
      role
      isAdmin
      isAE
      isCancelled
      isSuspended
      isAffiliate
      Plan {
        id
        createdAt
        updatedAt
        name
        description
        plan_id
        price_id
        price_description
        type
        trial_days
      }
    }
  }
`;

export const updatePrimaryPaymentMethod = gql`
  mutation Update_primary_payment_method($cvc: String!, $expYear: Float!, $expMonth: Float!, $cardNumber: String!, $name: String) {
    update_primary_payment_method(cvc: $cvc, exp_year: $expYear, exp_month: $expMonth, card_number: $cardNumber, name: $name)
  }
`;

export const updateUser = gql`
  mutation Update_user($phone: String, $name: String, $password: String, $email: String) {
    update_user(phone: $phone, name: $name, password: $password, email: $email)
  }
`;
